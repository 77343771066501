export enum CONTENT_API_ENDPOINTS {
  NAVBAR = '/api/navbar?locale=',
  LOGIN = `/api/login-page?locale=`,
  SIGNUP = '/api/signup-page?locale=',
  HOME = '/api/home-page?locale=',
  OTP = '/api/otp-page?locale=',
  CONSULTANT_DETAIL = '/api/consultant-detail-page?locale=',
  CATEGORY_DETAIL = '/api/category-details?locale=',
  APP_INFO = '/api/app-info?locale=',
  FAQS = '/api/faqs?locale=',
  TESTIMONIAL = '/api/testimonials?locale=',
  CONSULTATIONS = '/api/consultations-page?locale=',
  TERMS_AND_CONDITION = '/api/term-and-condition?locale=',
  PRIVACY_POLICY = '/api/privacy-policy?locale=',
  JOIN_AS_CONSULTANT = '/api/join-as-consultant?locale=',
  PROFILE_PAGE = '/api/profile-page?locale=',
  PROFILE_PAGE_V2 = '/api/profile-v2?locale=',
  APP_FAQ = '/api/app-faqs?locale=',
  MAIN_FAQ = '/api/main-faq?locale=',
  FOOTER='/api/footer?locale=',
  MOBILE_MARKETING='/api/mobile-page?locale=',
  REQUEST_PAGE_V2='/api/request-page-v2?locale=',
  REQUEST_PAGE_REVIEW_V2='/api/request-review-page-v2?locale=',
  CONSULTATION_SUCCESS_V2='/api/payment-success-page-v2?locale=',
  CUSTOM_CHAT_UI_V2='/api/custom-chat-ui-v2?locale=',
  CALL_LOGS_PAGE_CONTENT= "/api/call-logs-v2?locale=",
  HOME_FAQ = '/api/home-faqs?locale=',
  DELETE_ACCOUNT='/api/delete-account?locale='
}